<!--<template>
  <div @mouseenter="pause = true" @mouseleave="pause = false" class="w-full h-full hover:scale-125 duration-500">
    <TresCanvas shadow alpha realistic>
      <TresPerspectiveCamera :position="[0, 0, 50]" />

      <TresDirectionalLight cast-shadow :position="[-1000, 0, -1000]" :intensity="1" />
      <TresDirectionalLight cast-shadow :position="[1000, 0, -1000]" :intensity="1" />

      <TresDirectionalLight cast-shadow :position="[0, 3000, 10]" :intensity="1" />
      <TresDirectionalLight cast-shadow :position="[0, -3000, 10]" :intensity="1" />

      <TresAmbientLight :intensity="0.5" />

      <Suspense v-if="badgeOneModel">
        <primitive :object="badgeOneModel" />
      </Suspense>

      <Suspense v-if="badgeTwoModel">
        <primitive :object="badgeTwoModel" />
      </Suspense>

      <Suspense v-if="badgeThreeModel">
        <primitive :object="badgeThreeModel" />
      </Suspense>

      <TresPointLight :position="[0, 0, 5]" :intensity="5" />
    </TresCanvas>
  </div>
</template>

<script setup>
import { TresCanvas } from "@tresjs/core";
import { useFBX, TresPerspectiveCamera } from "@tresjs/cientos";
import { useLoader } from "@tresjs/core";
import { TextureLoader } from "three";

// const fbxModel = ref(null);
const showBadgeOne = ref(null);
const showBadgeTwo = ref(null);
const showBadgeThree = ref(null);

const badgeOneModel = ref(null);
const badgeTwoModel = ref(null);
const badgeThreeModel = ref(null);

const props = defineProps(["activeBadge", "isActive"]);
const { activeBadge, isActive } = toRefs(props);

const pause = ref(false);

watch(activeBadge, () => {
  if (activeBadge.value === 0) {
    showBadgeOne.value = true;
    showBadgeTwo.value = false;
    showBadgeThree.value = false;
  } else if (activeBadge.value === 1) {
    showBadgeOne.value = false;
    showBadgeTwo.value = true;
    showBadgeThree.value = false;
  } else if (activeBadge.value === 2) {
    showBadgeOne.value = false;
    showBadgeTwo.value = false;
    showBadgeThree.value = true;
  }
});

onMounted(async () => {
  const loadModel = async (meshName) => {
    let model = await useFBX(`/models/${meshName}`);

    let texture;

    if (meshName == "coin1.fbx") {
      texture = await useLoader(TextureLoader, "/models/metro_noord_1k.png");
    }

    if (meshName == "coin2.fbx") {
      texture = await useLoader(TextureLoader, "/models/evening_road_01_puresky_1k.png");
    }

    if (meshName == "coin3.fbx") {
      texture = await useLoader(TextureLoader, "/models/christmas_photo_studio_07_1k.png");
    }

    for (let child of model.children) {
      child.material.map = texture;
    }

    model.visible = false;

    return markRaw(model);
  };


  badgeOneModel.value = await loadModel("coin1.fbx");
  badgeTwoModel.value = await loadModel("coin2.fbx");
  badgeThreeModel.value = await loadModel("coin3.fbx");

  const raf = () => {

    if (isActive.value) {
      if (showBadgeOne.value) {
        badgeOneModel.value.visible = true;
        badgeTwoModel.value.visible = false;
        badgeThreeModel.value.visible = false;
      } else if (showBadgeTwo.value) {
        badgeOneModel.value.visible = false;
        badgeTwoModel.value.visible = true;
        badgeThreeModel.value.visible = false;
      } else if (showBadgeThree.value) {
        badgeOneModel.value.visible = false;
        badgeTwoModel.value.visible = false;
        badgeThreeModel.value.visible = true;
      }

      if (!showBadgeOne.value && !showBadgeTwo.value && !showBadgeThree.value) {
        badgeOneModel.value.visible = true; // default
        badgeTwoModel.value.visible = false;
        badgeThreeModel.value.visible = false;
      }

      let rate = 0.01;

      if (pause.value) {
        rate = 0.005;
      }

      badgeOneModel.value.rotation.y -= rate;
      badgeTwoModel.value.rotation.y -= rate;
      badgeThreeModel.value.rotation.y -= rate;
    } else {
      badgeOneModel.value.visible = false;
      badgeTwoModel.value.visible = false;
      badgeThreeModel.value.visible = false;
    }

    requestAnimationFrame(() => {
      raf();
    });
  };

  raf();
});
</script>

<style></style>
-->

<template>
  <!--<div @mouseenter="pause = true" @mouseleave="pause = false" class="w-full h-full duration-500">
    <Renderer ref="renderer" :resize="true" :alpha="true" :shadow="true" :antialias="true">
      <Camera :position="{ x: 0, y: 0, z: 50 }" />

      <Scene>
        <!~~ Directional Lights ~~>
        <DirectionalLight :castShadow="true" :position="{ x: -100, y: 0, z: -100 }" :intensity="1" />
        <DirectionalLight :castShadow="true" :position="{ x: 100, y: 0, z: -100 }" :intensity="1" />
        <DirectionalLight :castShadow="true" :position="{ x: 0, y: 300, z: 10 }" :intensity="1" />
        <DirectionalLight :castShadow="true" :position="{ x: 0, y: -300, z: 10 }" :intensity="1" />
  
        <!~~ Ambient Light ~~>
        <AmbientLight :intensity="1" />
  
        <!~~ Model Components with Events ~~>
        <FbxModel ref="badgeOneModel" src="/models/coin1.fbx" @load="onModelLoad($event,'one')" />
        <FbxModel ref="badgeTwoModel" src="/models/coin2.fbx" @load="onModelLoad($event,'two')" />
        <FbxModel ref="badgeThreeModel" src="/models/coin3.fbx" @load="onModelLoad($event,'three')" />
  
        <!~~ Point Light ~~>
        <PointLight :position="{ x: 0, y: 0, z: 5 }" :intensity="10" />

      </Scene>

    </Renderer>
  </div>-->
  <div class="flex justify-center items-center w-full h-full relative">
    <img class="w-full h-full object-contain duration-1000 absolute" :class="[showBadgeOne?'opacity-100':'opacity-0']" src="/tier1.gif" alt="">
    <img class="w-full h-full object-contain duration-1000 absolute" :class="[showBadgeTwo?'opacity-100':'opacity-0']" src="/tier2.gif" alt="">
    <img class="w-full h-full object-contain duration-1000 absolute" :class="[showBadgeThree?'opacity-100':'opacity-0']" src="/tier3.gif" alt="">

    <!-- <img class="w-full h-full object-contain" v-if="showBadgeTwo" src="/tier2.gif" alt=""> -->
    <!-- <img class="w-full h-full object-contain" v-if="showBadgeThree" src="/tier3.gif" alt=""> -->

  </div>
</template>

<script setup>

const props = defineProps(["activeBadge", "isActive"]);
const { activeBadge } = toRefs(props);

const showBadgeOne = ref(true);
const showBadgeTwo = ref(null);
const showBadgeThree = ref(null);


watch(activeBadge, () => {
  if (activeBadge.value === 0) {
    showBadgeOne.value = true;
    showBadgeTwo.value = false;
    showBadgeThree.value = false;
  } else if (activeBadge.value === 1) {
    showBadgeOne.value = false;
    showBadgeTwo.value = true;
    showBadgeThree.value = false;
  } else if (activeBadge.value === 2) {
    showBadgeOne.value = false;
    showBadgeTwo.value = false;
    showBadgeThree.value = true;
  }else{
    showBadgeOne.value = true;
    showBadgeTwo.value = false;
    showBadgeThree.value = false;
  }
});

/*const renderer = ref(null);

const onModelLoad = (event,key) => {
  // Handle model once loaded, event contains the loaded Three.js Object3D
  // const model = event.detail;
  // console.log('Model loaded:', event, key);

  // if (key === 'one') {
  //   badgeOneModel.value = event;
  //   badgeOneModel.value.visible = false;
    
  // } else if (key === 'two') {
  //   badgeTwoModel.value = event;
  //   badgeTwoModel.value.visible = false;
  // } else if (key === 'three') {
  //   badgeThreeModel.value = event;
  //   badgeThreeModel.value.visible = false;
  // }
  // Perform any modifications or setup here

  let texture;



 


  if (key == "one") {
      // texture = await useLoader(TextureLoader, "/models/metro_noord_1k.png");
      texture = new TextureLoader().load('/models/christmas_photo_studio_07_1k.png');



    }

    if (key == "two") {
      // texture = await useLoader(TextureLoader, "/models/evening_road_01_puresky_1k.png");
      texture = new TextureLoader().load('/models/evening_road_01_puresky_1k.png');
    }

    if (key == "three") {
      // texture = await useLoader(TextureLoader, "/models/christmas_photo_studio_07_1k.png");
      texture = new TextureLoader().load('/models/christmas_photo_studio_07_1k.png');
    }

    for (let child of event.children) {

      const myNewMaterial = new THREE.MeshStandardMaterial({
        metalness: 1,
        roughness: 0.5,
        // transparent: true,
        // opacity: 0.5,
        envMapIntensity: 1,
        envMap: texture,
        // side: THREE.FrontSide,  // to test change this to `THREE.DoubleSide` just in case.
        color: child.material.color
      });


      child.material = myNewMaterial;
    }

      

    // if (key == "two") {
    //   texture = await useLoader(TextureLoader, "/models/evening_road_01_puresky_1k.png");
    // }

    // if (key == "three") {
    //   texture = await useLoader(TextureLoader, "/models/christmas_photo_studio_07_1k.png");
    // }

    
    // console.log(event)
};



onMounted( () => {
  renderer.value.onBeforeRender(() => {
    // box.rotation.x += 0.01;

    // console.log('renderer.value', badgeOneModel.value);

    if(badgeOneModel.value?.o3d && badgeTwoModel.value?.o3d && badgeThreeModel.value?.o3d){
      

      if (isActive.value) {
      if (showBadgeOne.value) {
        badgeOneModel.value.o3d.visible = true;
        badgeTwoModel.value.o3d.visible = false;
        badgeThreeModel.value.o3d.visible = false;
      } else if (showBadgeTwo.value) {
        badgeOneModel.value.o3d.visible = false;
        badgeTwoModel.value.o3d.visible = true;
        badgeThreeModel.value.o3d.visible = false;
      } else if (showBadgeThree.value) {
        badgeOneModel.value.o3d.visible = false;
        badgeTwoModel.value.o3d.visible = false;
        badgeThreeModel.value.o3d.visible = true;
      }

      if (!showBadgeOne.value && !showBadgeTwo.value && !showBadgeThree.value) {
        badgeOneModel.value.o3d.visible = true; // default
        badgeTwoModel.value.o3d.visible = false;
        badgeThreeModel.value.o3d.visible = false;
      }

      let rate = 0.01;

      if (pause.value) {
        rate = 0.005;
      }

      badgeOneModel.value.o3d.rotation.y -= rate;
      badgeTwoModel.value.o3d.rotation.y -= rate;
      badgeThreeModel.value.o3d.rotation.y -= rate;
    } else {
      // badgeOneModel.value.visible = false;
      // badgeTwoModel.value.visible = false;
      // badgeThreeModel.value.visible = false;
    }


    }

    
  });
})*/

// const onModelError = (error) => {
//   console.error('Failed to load model:', error);
// };
</script>
